import { Card } from 'antd';
import React from 'react';
import { NormalProgrammeComponent, NormalProgramme } from '../../utils';
import { observer } from 'mobx-react';

const normalProgramme = new NormalProgramme({
  filterItems: [
    {
      type: 'dateRange',
      field: 'dateRange',
      label: 'dateRange',
    },
    {
      type: 'dateStart',
      field: 'dateStart',
      label: 'dateStart',
    },
    {
      type: 'dateEnd',
      field: 'dateEnd',
      label: 'dateEnd',
    },
    {
      type: 'boolean',
      field: 'boolean',
      label: 'boolean',
    },
    {
      type: 'cascader',
      field: 'cascader',
      label: 'cascader',
      data: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                },
              ],
            },
          ],
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          children: [
            {
              value: 'nanjing',
              label: 'Nanjing',
              children: [
                {
                  value: 'zhonghuamen',
                  label: 'Zhong Hua Men',
                },
              ],
            },
          ],
        },
      ],
    },
    {
      type: 'input',
      field: 'input',
      label: 'input',
    },
    {
      type: 'inputNumberGroup',
      field: 'inputNumberGroup',
      label: 'inputNumberGroup',
    },
    {
      type: 'select',
      field: 'select',
      label: 'select',
      mode: 'multiple',
      data: Array(1000)
        .fill(null)
        .map((_, index) => ({
          value: `${index}`,
          label: `${index}`,
        })),
    },
    {
      type: 'treeSelect',
      field: 'treeSelect',
      label: 'treeSelect',
      treeData: [
        {
          value: 'zhejiang',
          label: 'Zhejiang',
          title: 'Zhejiang',
          children: [
            {
              value: 'hangzhou',
              label: 'Hangzhou',
              title: 'Hangzhou',
              children: [
                {
                  value: 'xihu',
                  label: 'West Lake',
                  title: 'West Lake',
                },
              ],
            },
          ],
        },
        {
          value: 'jiangsu',
          label: 'Jiangsu',
          title: 'Jiangsu',
          children: [
            {
              value: 'nanjing',
              label: 'Nanjing',
              title: 'Nanjing',
              children: [
                {
                  value: 'zhonghuamen',
                  label: 'Zhong Hua Men',
                  title: 'Zhong Hua Men',
                },
              ],
            },
          ],
        },
      ],
    },
  ],
  handleSearch: () => Promise.resolve(),
});

export default observer(() => (
  <Card size="small">
    <NormalProgrammeComponent store={normalProgramme} />
    <div>{JSON.stringify(normalProgramme.filterItems.params)}</div>
  </Card>
));
